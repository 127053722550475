import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../../components/SEO'
import Layout from '../../layouts/index'

const Services = (props) => {
  const services = props.data.allMarkdownRemark.edges
  return (
    <Layout bodyClass="page-services">
      <SEO title="Services" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Services</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {services.map((edge) => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <Link className="service-link" to={edge.node.frontmatter.path}>
                <div className="card service service-teaser">
                  <div className="card-content">
                    <h2>{edge.node.frontmatter.title}</h2>
                    <p>{edge.node.excerpt}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/services/" } }, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`

export default Services
